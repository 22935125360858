<template>
    <OIframe ref="iframeRef" :src="parseMenuTabUrl(url)" :useLoadingMessage="useLoadingMessage" @load="() => handleLoaded()" />
</template>

<script setup lang="ts">
import type { DataObject } from 'o365-dataobject';
import type GridMenuTabs from './DataGrid.GridMenu.ts';

import { configurableRegister } from 'o365-modules';
import { ref } from 'vue';
import { useDataObjectEventListener } from 'o365-vue-utils';
import { OIframe } from 'o365-ui-components';

export interface IProps {
    url: string 
    useLoadingMessage: boolean
    menuTabs: GridMenuTabs
}

const props = defineProps<IProps>();

const iframeRef = ref();
const dataObject: DataObject = props.menuTabs.gridProps.dataObject;

function parseMenuTabUrl(pHref: string) {
    if (pHref == null) { 
        return pHref;
    } else if (pHref.includes('{{')) {
        return props.menuTabs.getParsedUrl(pHref);
    } else {
        return pHref;
    }
    
}

useDataObjectEventListener(dataObject, 'DataLoaded', () => {
    postLoadedMessage();
})

useDataObjectEventListener(dataObject, 'CurrentIndexChanged', () => {
    postIndexChangeMessage();
})

function handleLoaded() {
    setTimeout(() => {
        postLoadedMessage();
    }, 200);
}

function postLoadedMessage() {
    const recordSourceOptions = dataObject.recordSource.getOptions();
    const uniqueField = dataObject.fields.uniqueField;

    const payload = {
        type: 'system-register-tab',
        action: 'loaded',
        uniqueValue: dataObject.current ? dataObject.current[uniqueField] : null,
        id: dataObject.current ? dataObject.current.ID : null,
        options: {
            registerId: configurableRegister.id,
            contextId: recordSourceOptions.contextId,
            definitionProc: recordSourceOptions.definitionProc,
            definitionProcParameters: recordSourceOptions.definitionProcParameters,
            distinctRows: recordSourceOptions.distinctRows,
            fields: recordSourceOptions.fields,
            filterString: recordSourceOptions.filterString,
            masterDetailString: recordSourceOptions.masterDetailString,
            maxRecords: recordSourceOptions.maxRecords,
            searchFunction: recordSourceOptions.searchFunction,
            searchString: recordSourceOptions.searchString,
            skip: recordSourceOptions.skip,
            sqlStatementParameters: recordSourceOptions.sqlStatementParameters,
            whereClause: recordSourceOptions.whereClause,
        }
    };
    iframeRef.value?.post(payload);
}

function postIndexChangeMessage() {
    const uniqueField = dataObject.fields.uniqueField;

    const payload = {
        type: 'system-register-tab',
        action: 'indexChanged',
        uniqueValue: dataObject.current ? dataObject.current[uniqueField] : null,
        id: dataObject.current ? dataObject.current.ID : null
    };
    iframeRef.value?.post(payload);
}
</script>